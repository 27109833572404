if (window.innerWidth >= 1500) {
  function initHeadMovement() {
    const head = document.querySelector('#head');
    const leftEye = document.querySelector('#left-eye-pupil');
    const rightEye = document.querySelector('#right-eye');
    const nose = document.querySelector('#nose');

    const headElements = document.querySelectorAll(
      '#head g:not(#left-ear, #right-ear)'
    );

    const leftEar = document.querySelector('#left-ear');
    const rightEar = document.querySelector('#right-ear');

    document.addEventListener('mousemove', function (e) {
      const relativeYInPercentage = (100 / window.innerHeight) * e.clientY;
      const relativeXInPercentage = (100 / window.innerWidth) * e.clientX;

      const relativeCursorPositions = {
        x: relativeXInPercentage,
        y: relativeYInPercentage,
      };

      const relativeNosePosition = {
        x: (100 / window.innerWidth) * nose.getBoundingClientRect().x,
        y: (100 / window.innerHeight) * nose.getBoundingClientRect().y,
      };

      const cursorPositionRelativeToNose = {
        x: relativeCursorPositions.x - relativeNosePosition.x,
        y: relativeCursorPositions.y - relativeNosePosition.y,
      };

      const headX =
        (2 / relativeNosePosition.x) * cursorPositionRelativeToNose.x;
      const headY =
        (2 / relativeNosePosition.y) * cursorPositionRelativeToNose.y;

      const eyeY = (2 / window.innerHeight) * e.clientY - 1;
      const eyeX = (2 / window.innerWidth) * e.clientX - 1;

      headElements.forEach((element) => {
        element.style.transformOrigin = '0px 0px';
        element.style.transform = `matrix(1, 0, 0, 1, ${headX / 2}, ${
          headY / 2
        })`;
      });
      head.style.transform = `matrix(1, 0, 0, 1, ${headX}, ${headY})`;
      rightEar.style.transformOrigin = '0px 0px';
      rightEar.style.transform = `matrix(1, 0, 0, 1, ${-headX}, ${
        -headY * 1.5
      })`;
      leftEar.style.transform = `matrix(1, 0, 0, 1, ${-headX}, ${
        -headY * 1.5
      })`;
      leftEar.style.transformOrigin = '0px 0px';
    });

    const timeline = new gsap.timeline();
    timeline.staggerFrom(
      '#birthmarks circle',
      2,
      { opacity: 0, ease: Bounce.easeOut },
      0.1
    );
  }

  function createLogoTimeLine(logoId) {
    const logo = document.querySelector(`#${logoId}`);
    const ellipse = document.querySelector(`#${logoId} ellipse`);
    const timeline = new gsap.timeline();
    timeline.fromTo(ellipse, 0.3, { drawSVG: 0 }, { drawSVG: true });
    timeline.pause();
    logo.addEventListener('mouseenter', () => {
      timeline.restart();
      ellipse.style.strokeWidth = '10px';
      timeline.play();
    });
    logo.addEventListener(
      'mouseleave',
      () => (ellipse.style.strokeWidth = '0px')
    );
  }

  function createLogoTimelines() {
    createLogoTimeLine('twitter');
    createLogoTimeLine('linkedin');
    createLogoTimeLine('instagram');
  }

  initHeadMovement();
  createLogoTimelines();

  const kevTimeline = gsap.timeline();
  kevTimeline.from('#Me', 2, { y: 200 });
  ScrollTrigger.create({
    animation: kevTimeline,
    trigger: '.contact-container',
    start: 'top top',
    end: '+=2000',
  });
}
